
.password-protect-page {
    padding: 50px;
    background-image: url("https://pluviophile.net/wp-content/uploads/wet-empty-road-wallpaper.jpg");
    background-size: cover;
    background-position: center;
  }

.password-protect-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.password-protect-page h2 {
    margin-bottom: 20px;
}

.password-protect-page form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.password-protect-page input {
    padding: 10px;
    margin-bottom: 10px;
    font-size: 16px;
}

.password-protect-page button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}
