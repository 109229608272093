/* Container for the scanner and results */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  /* Header styling */
  .header {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* QR scanner container styling */
  .scanner-container {
    width: 100%;
    max-width: 400px;
    border: 2px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
  }
  
  /* QR scanner area styling */
  .qr-scanner {
    width: 100%;
  }
  
  /* Result text styling */
  .result-text, .success-text, .error-text {
    margin-top: 20px;
    font-size: 16px;
    color: #333;
  }
  
  /* Success text styling */
  .success-text {
    color: green;
  }
  
  /* Error text styling */
  .error-text {
    color: red;
  }
  
  /* Responsive design for mobile */
  @media (max-width: 600px) {
    .header {
      font-size: 20px;
    }
  
    .result-text, .success-text, .error-text {
      font-size: 14px;
    }
  
    .scanner-container {
      max-width: 100%;
    }
  }
  