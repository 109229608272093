 

.home-background{
    background-image: url("https://d1o2pwfline4gu.cloudfront.net/m/t/28099/28089191/a-0052.jpg");
    background-size: cover;
    background-position: center;
}


.home-heading{
  font-size: 40px;  
  text-align: center;  
}

.square-btn {
    width: 350px; /* Adjust width as needed */
    height: 350px; /* Adjust height as needed */
    font-size: 1.5rem; /* Increase font size if needed */
    padding: 20px; /* Adjust padding as needed */
  }
  
  
  .buttonWithBackground1 {
    background-image: url("https://geb.vgf.mybluehost.me/wp-content/uploads/2021/05/pexels-daniel-kist-70955.jpg");
    background-size: cover;
    position: relative;
    display: inline-block;
    text-decoration: none;
    border: 8px solid; 
    border-color: rgba(66, 65, 65, 0.688);
  }

  .buttonWithBackground2 {
    background-image: url("https://geb.vgf.mybluehost.me/wp-content/uploads/2021/05/pexels-jerry-wang-3768652-1.jpg");
    background-size: cover;
    position: relative;
    display: inline-block;
    text-decoration: none;
    border: 8px solid; 
    border-color: rgba(66, 65, 65, 0.688);
  }
  
  .buttonOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(185, 212, 227, 0.475);
    z-index: 1;
    border-radius: 5px;
    transition: opacity 0.3s;
    opacity: 0; /* Initially transparent */
  }
  
  .buttonContent {
    position: relative;
    z-index: 2;
    color: white;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center; /* Center horizontally */ 
    height: 100%; /* Ensure the content takes up the full height of the button */
  }
  
  
  .buttonContent img {
    display: block;
    width: 100%; /* Make the image fill the button */
    border-radius: 5px;
  }
  
  .buttonWithBackground1:hover .buttonOverlay {
    opacity: 0.7; /* More opaque on hover */
  }
  
  /* Additional hover effects */
  .buttonWithBackground1:hover .buttonContent {
    color: #f0f0f0;
  }
  
    
  .buttonWithBackground2:hover .buttonOverlay {
    opacity: 0.9; /* More opaque on hover */
  }
  
  /* Additional hover effects */
  .buttonWithBackground2:hover .buttonContent {
    color: #697067;
  }

  .show-all-member button {
  padding: 10px;
  background-color: #9a1717;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.show-all-member button:hover {
  background-color: #532628;
}

 

  /* For screens smaller than 768px (phones), display buttons vertically */
@media (max-width: 768px) {
  .container-fluid .row {
    flex-direction: column;
    align-items: center;
  }

  .col-md-4 {
    width: 100%; /* Make each column full width */
    margin-bottom: 50px; /* Add some spacing between buttons */
  }
}

/* For screens larger than 768px (computers), display buttons horizontally */
@media (min-width: 769px) {
  .container-fluid .row {
    flex-direction: row;
    justify-content: center;
  }

  .col-md-4 {
    width: auto; /* Reset width to auto for horizontal layout */
    margin-bottom: 0; /* Reset margin */
  }
} 
