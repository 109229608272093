/* Background styling */
.home-background {
  background-image: url("https://d1o2pwfline4gu.cloudfront.net/m/t/28099/28089191/a-0052.jpg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center;
}

/* Spacing */
.spacer {
  height: 50px; /* Adjust as needed for spacing */
}

/* Headings */
.home-heading {
  text-align: center;
}

.main-title {
  font-family: 'serif';
  font-size: 60px;
  font-weight: bold;
  color: black;
}

.welcome-title {
  font-size: 32px;
  font-weight: bold;
  color: black;
}

.welcome-text {
  font-size: 18px;
  color: black;
  line-height: 1.6;
  background-color: rgba(128, 128, 128, 0.2);
  padding: 10px;
  border-radius: 5px;
  text-align: left; /* Align text to the left */
  margin: 0 auto; /* Center the box horizontally */
  max-width: 800px; /* Limit width to prevent overflow on small screens */
}

/* Button Styling */
.buttonWithBackground1 {
  width: 350px; /* Larger square size */
  height: 350px; /* Larger square size */
  background-image: url("https://geb.vgf.mybluehost.me/wp-content/uploads/2021/05/pexels-daniel-kist-70955.jpg");
  background-size: cover;
  position: relative;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(185, 212, 227, 0.475);
  z-index: 1;
  transition: opacity 0.3s;
  opacity: 0; /* Initially transparent */
  border-radius: 5px;
}

.buttonContent {
  position: relative;
  z-index: 2;
  color: white;
  text-align: center;
}

.button-text {
  font-family: 'serif';
  font-size: 64px;
  font-weight: bold;
}

.buttonWithBackground1:hover .buttonOverlay {
  opacity: 0.7; /* More opaque on hover */
}

.buttonWithBackground1:hover .buttonContent {
  color: #f0f0f0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .home-background {
    padding: 15px;
  }

  .main-title {
    font-size: 48px;
  }

  .welcome-title {
    font-size: 28px;
  }

  .welcome-text {
    font-size: 16px;
    max-width: 90%;
  }

  .buttonWithBackground1 {
    width: 280px;
    height: 280px;
  }

  .button-text {
    font-size: 48px;
  }
}

@media (max-width: 480px) {
  .home-background {
    padding: 10px;
  }

  .main-title {
    font-size: 36px;
  }

  .welcome-title {
    font-size: 24px;
  }

  .welcome-text {
    font-size: 14px;
    max-width: 100%;
  }

  .buttonWithBackground1 {
    width: 220px;
    height: 220px;
  }

  .button-text {
    font-size: 20px;
  }
}
